import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import "./styles/navBar.css";

const NavBar = (props) => {
	const { active } = props;	
	const [checked, setChecked] = useState(localStorage.getItem("theme") === "light-theme"?false:true)
	const [theme, setTheme] = useState(
		localStorage.getItem("theme") || "dark-theme"
	);

	// change theme function
	const themeToggle = () => {
		if (theme === "light-theme") {
			setTheme("dark-theme");
			setChecked(true);
		} else {
			setTheme("light-theme");
			setChecked(false);
		}
	};

	// save the theme preference to local storage
	useEffect(() => {
		document.body.className = theme;
		localStorage.setItem("theme", theme);
	}, [theme]);

	return (
		<React.Fragment>
			<div className="nav-container">
				<nav className="navbar">
					<div className="nav-background">
						<ul className="nav-list">
							<li
								className={
									active === "home"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/">Home</Link>
							</li>
							<li
								className={
									active === "about"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/about">About</Link>
							</li>
							<li
								className={
									active === "projects"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/projects">Projects</Link>
							</li>
							<li
								className={
									active === "research"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/research">Research</Link>
							</li>
							<li
								className={
									active === "articles"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/articles">Articles</Link>
							</li>
							<li className="nav-item-icon">
								<Switch 
									checked={checked}
									onChange={themeToggle} 
									handleDiameter={18} 
									height={16} 
									width={36}
									checkedIcon={false}
									uncheckedIcon={false}
									uncheckedHandleIcon={<FontAwesomeIcon icon={faMoon} color="white" size="xs" className="nav-switch-icon"/>}
									checkedHandleIcon={<FontAwesomeIcon icon={faSun} color="black" size="xs" className="nav-switch-icon"/>}
									onColor="#2a3950"
									offHandleColor="#101e38"
									// onHandleColor=""
									aria-label="Dark mode"
								/>
							</li>
						</ul>						
					</div>					
				</nav>			
			</div>			
		</React.Fragment>
	);
};

export default NavBar;
