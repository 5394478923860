import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import { faEnvelope, 
		faNewspaper, 
		faTerminal, 
		faGraduationCap, 
		faTrophy,
		faRss } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faTwitter,	
	faGithub,
	faStackOverflow,
	faLinkedin
} from "@fortawesome/free-brands-svg-icons";

import Logo from "../components/common/logo";
import Footer from "../components/common/footer";
import NavBar from "../components/common/navBar";
import Article from "../components/homepage/article";
import Works from "../components/homepage/works";
import AllProjects from "../components/projects/allProjects";
import AllMedia from "../components/media/allMedia";
import Memberships from "../components/memberships/memberships"

import INFO from "../data/user";
import SEO from "../data/seo";
import myArticles from "../data/researches";

import "./styles/homepage.css";

const Homepage = () => {
	const [stayLogo, setStayLogo] = useState(false);
	const [logoSize, setLogoSize] = useState(80);
	// const [oldLogoSize, setOldLogoSize] = useState(80);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		// Function to calculate and set logo size based on scroll
		const adjustLogoSize = (scrollPosition) => {
			let newLogoSize = 80 - (scrollPosition * 4) / 10;
	
			if (newLogoSize <= 40) {
				setLogoSize(40);
				// setOldLogoSize(40);
				setStayLogo(true);
			} else {
				setLogoSize(newLogoSize);
				// setOldLogoSize(newLogoSize);
				setStayLogo(false);
			}
		};
	
		// Handle the initial scroll position on component mount
		adjustLogoSize(window.scrollY);
	
		// Handle scroll events
		const handleScroll = () => {
			let scroll = Math.round(window.scrollY, 2);
			adjustLogoSize(scroll);
		};
	
		window.addEventListener("scroll", handleScroll);
	
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	// useEffect(() => {
	// 	const handleScroll = () => {
	// 		let scroll = Math.round(window.scrollY, 2);

	// 		let newLogoSize = 80 - (scroll * 4) / 10;

	// 		if (newLogoSize < oldLogoSize) {
	// 			if (newLogoSize > 40) {
	// 				setLogoSize(newLogoSize);
	// 				setOldLogoSize(newLogoSize);
	// 				setStayLogo(false);
	// 			} else {
	// 				setStayLogo(true);
	// 			}
	// 		} else {
	// 			setLogoSize(newLogoSize);
	// 			setStayLogo(false);
	// 		}
	// 	};

	// 	window.addEventListener("scroll", handleScroll);
	// 	return () => window.removeEventListener("scroll", handleScroll);
	// }, [logoSize, oldLogoSize]);

	const currentSEO = SEO.find((item) => item.page === "home");

	const logoStyle = {
		display: "flex",
		position: stayLogo ? "fixed" : "relative",
		top: stayLogo ? "3vh" : "auto",
		zIndex: 999,
		border: stayLogo ? "1px solid white" : "none",
		borderRadius: stayLogo ? "50%" : "none",
		boxShadow: stayLogo ? "0px 4px 10px rgba(0, 0, 0, 0.25)" : "none",
	};

	return (
		<React.Fragment>
			<Helmet>
				<title>{INFO.main.title}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="home" />
				<div className="content-wrapper">
					<div className="homepage-logo-container">
						<div style={logoStyle}>
							<Logo width={logoSize} link={false} />
						</div>
					</div>

					<div className="homepage-container">
						<div className="homepage-first-area">
							<div className="homepage-first-area-left-side">
								<div className="title homepage-title">
									{INFO.homepage.title}
								</div>

								<div className="subtitle homepage-subtitle">
									{INFO.homepage.description}
								</div>
							</div>

							<div className="homepage-first-area-right-side">
								<div className="homepage-image-container">
									<div className="homepage-image-wrapper">
										<img
											src="about.jpg"
											alt="about"
											className="homepage-image"
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="homepage-socials">
							<a
								href={INFO.socials.linkedin}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faLinkedin}
									className="homepage-social-icon"
								/>
							</a>
							<a
								href={INFO.socials.github}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faGithub}
									className="homepage-social-icon"
								/>
							</a>
							<a
								href={INFO.socials.stackoverflow}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faStackOverflow}
									className="homepage-social-icon"
								/>
							</a>	
							<a
								href={INFO.socials.twitter}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faTwitter}
									className="homepage-social-icon"
								/>
							</a>						
							<a
								href={`mailto:${INFO.main.email}`}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faEnvelope}
									className="homepage-social-icon"
								/>
							</a>
						</div>

						<div className="homepage-after-title">
							<div className="homepage-articles">
								<div className="title-2 homepage-title">
									<FontAwesomeIcon
										icon={faNewspaper}
										className="homepage-social-icon"
									/>
									Publications
								</div>
								{myArticles.slice(0,2).map((article, index) => (
									<div
										className="homepage-article"
										key={(index + 1).toString()}
									>
										<Article
											key={(index + 1).toString()}
											date={article.date}
											title={article.title}
											description={article.description}
											link={article.url}
										/>
									</div>
								))}	
								<div className="homepage-article-more-link">
									<Link to={"/research"} style={{width: '100%'}}>More articles...</Link>								
								</div>
							</div>

							<div className="homepage-works">
								<Works />
							</div>
						</div>
						
						<div className="homepage-projects">
							<div className="title-2 homepage-title">
								<FontAwesomeIcon
									icon={faGraduationCap}
									className="homepage-social-icon"
								/>
								Professional Memberships
							</div>
							<Memberships />
						</div>

						<div className="homepage-projects">
							<div className="title-2 homepage-title">
								<FontAwesomeIcon
									icon={faTrophy}
									className="homepage-social-icon"
								/>
								Awards and accolades
							</div>
							<AllMedia media_type="awards"/>
						</div>

						<div className="homepage-projects">
							<div className="title-2 homepage-title">
								<FontAwesomeIcon
									icon={faTerminal}
									className="homepage-social-icon"
								/>
								Projects
							</div>
							<AllProjects displayCount={6}/>
							<div className="homepage-article-more-link">
								<Link to={"/projects"} style={{width: '100%'}}>See other projects...</Link>								
							</div>
						</div>	

						<div className="homepage-projects">
							<div className="title-2 homepage-title">
								<FontAwesomeIcon
									icon={faRss}
									className="homepage-social-icon"
								/>
								Featured in the news
							</div>
							<AllMedia />
						</div>					

						<div className="page-footer">
							<Footer />
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Homepage;
