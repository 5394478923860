import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Work Experience"
				body={
					<div className="works-body">
						<div className="work">
							<img
								src="./aws_logo.jpg"
								alt="AWS"
								className="work-image"
							/>
							<div className="work-title">Amazon Web Services</div>
							<div className="work-subtitle">
								Sr. AI Services Solutions Architect
							</div>
							<div className="work-duration">2020 - Present</div>
						</div>

						<div className="work">
							<img
								src="./petco_logo.jpg"
								alt="Petco"
								className="work-image"
							/>
							<div className="work-title">Petco Health & Wellness Co. Inc.</div>
							<div className="work-subtitle">
								Sr. Domain Architect
							</div>
							<div className="work-duration">2019 - Present</div>
						</div>

						<div className="work">
							<img
								src="./oracle_logo.jpg"
								alt="Oracle"
								className="work-image"
							/>
							<div className="work-title">Oracle Corp.</div>
							<div className="work-subtitle">
								Senior Managing Consultant
							</div>
							<div className="work-duration">2019 - Present</div>
						</div>

						<div className="work">
							<img
								src="./ibm_logo.jpg"
								alt="IBM"
								className="work-image"
							/>
							<div className="work-title">IBM Inc.</div>
							<div className="work-subtitle">
								Package Solution Architect
							</div>
							<div className="work-duration">2019 - Present</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Works;
