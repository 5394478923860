import React from "react";

import Media from "./media";

import INFO from "../../data/user";

import "./styles/allMedia.css";

const AllMedia = ({media_type="news"}) => {
	return (
		<div className="all-medias-container">
			{
				(media_type === "news")?
				INFO.medias.map((media, index) => (
					<div className="all-medias-media" key={index}>
						<Media
							logo={media.logo}						
							description={media.description}
							linkText={media.linkText}
							link={media.link}
						/>
					</div>
				))
				:INFO.awards.map((media, index) => (
					<div className="all-medias-media" key={index}>
						<Media
							logo={media.logo}						
							description={media.description}
							linkText={media.linkText}
							link={media.link}
						/>
					</div>
				))
			}
			{/* {INFO.medias.map((media, index) => (
				<div className="all-medias-media" key={index}>
					<Media
						logo={media.logo}						
						description={media.description}
						linkText={media.linkText}
						link={media.link}
					/>
				</div>
			))} */}
		</div>
	);
};

export default AllMedia;
