

const myResearch = [
    {
        "date": "24 Aug 2019",
        "title": "Media Insights Engine for Advanced Media Analysis: A Case Study of a Computer Vision Innovation for Pet Health Diagnosis",
        "description": "This paper presents a case study of how Petco, a leading pet retailer, innovated their pet health analysis processes using the Media Insights Engine to reduce the time to first diagnosis.",
        "url": "https://www.researchgate.net/publication/379035181_Media_Insights_Engine_for_Advanced_Media_Analysis_A_Case_Study_of_a_Computer_Vision_Innovation_for_Pet_Health_Diagnosis",
        "keywords": [
            "Computer Vision",
            "Amazon Rekognition",
            "Anjan Biswas",
            "Anjanava Biswas"
        ],
        "hero_image": "../../rg.png"
    },
    {
        "date": "21 May 2024",
        "title": "FinEmbedDiff: A Cost-Effective Approach of Classifying Financial Documents with Vector Sampling using Multi-modal Embedding Models",
        "description": "In this paper we propose FinEmbedDiff, a cost-effective vector sampling method that leverages pre-trained multi-modal embedding models to classify financial documents. ",
        "url": "https://arxiv.org/abs/2406.01618",
        "keywords": [
            "Large Language Models",
            "Embedding Models",
            "Multi-modal",
            "Document classification",
            "Anjan Biswas",
            "Anjanava Biswas"
        ],
        "hero_image": "../../arxiv-logo.png"
    },
    {
        "date": "05 May 2024",
        "title": "Intelligent Clinical Documentation: Harnessing Generative AI for Patient-Centric Clinical Note Generation",
        "description": "This paper explores the potential of generative AI (Artificial Intelligence) to streamline the clinical documentation process, specifically focusing on generating SOAP (Subjective, Objective, Assessment, Plan) and BIRP (Behavior, Intervention, Response, Plan) notes.",
        "url": "https://arxiv.org/abs/2405.18346",
        "keywords": [
            "Large Language Models",
            "Clinical Notes",
            "Healthcare",
            "Clinician burn-out",
            "Anjan Biswas",
            "Anjanava Biswas",
            "Wrick Talukdar"
        ],
        "hero_image": "../../arxiv-logo.png"
    },
    {
        "date": "18 May 2024",
        "title": "Synergizing Unsupervised and Supervised Learning: A Hybrid Approach for Accurate Natural Language Task Modeling",
        "description": "In this paper we demonstrate a methodology that integrates an unsupervised module that learns representations from unlabeled corpora (e.g., language models, word embeddings) and a supervised module that leverages these representations to enhance task-specific models.",
        "url": "https://arxiv.org/abs/2406.01096",
        "keywords": [
            "Supervised Learning",
            "Unsupervised Learning",
            "Machine Learning",
            "NLP",
            "Anjan Biswas",
            "Anjanava Biswas",
            "Wrick Talukdar"
        ],
        "hero_image": "../../arxiv-logo.png"
    },
    {
        "date": "28 May 2024",
        "title": "Enhancing Clinical Documentation with Synthetic Data: Leveraging Generative Models for Improved Accuracy",
        "description": "This paper proposes a novel approach to augment clinical documentation by leveraging synthetic data generation techniques to generate realistic and diverse clinical transcripts.",
        "url": "https://arxiv.org/abs/2406.06569",
        "keywords": [
            "Large Language Models",
            "Healthcare",
            "Synthetic Data",
            "Data augmentation",
            "Anjan Biswas",
            "Anjanava Biswas",
            "Wrick Talukdar"
        ],
        "hero_image": "../../arxiv-logo.png"
    },
    {
        "date": "29 March 2024",
        "title": "Robustness of Structured Data Extraction from In-plane Rotated Documents using Multi-Modal Large Language Models (LLM)",
        "description": "This paper paper investigates the accuracy of multi-modal LLMs models on structured data extraction from documents, which can be significantly affected by document in-plane rotation, also known as skew, a common issue in real-world scenarios for scanned documents.",
        "url": "https://arxiv.org/abs/2406.10295",
        "keywords": [
            "Large Language Models",
            "Intelligent Document Processing",
            "Structured Data Extraction",
            "IDP",
            "Multi-modal Large Language Models",
            "Anjan Biswas",
            "Anjanava Biswas",
            "Wrick Talukdar"
        ],
        "hero_image": "../../arxiv-logo.png"
    },
    {
        "date": "01 November 2023",
        "title": "Guardrails for trust, safety, and ethical development and deployment of Large Language Models (LLM)",
        "description": "This paper paper proposes a framework for implementing safeguards and guardrailing techniques is imperative for applications to ensure that the content generated by LLMs are safe, secure, and ethical.",
        "url": "https://doi.org/10.55662/JST.2023.4605",
        "keywords": [
            "Large Language Models",
            "Trust",
            "Safety",
            "Data Security",            
            "Multi-modal Large Language Models",
            "Anjan Biswas",
            "Anjanava Biswas",
            "Wrick Talukdar"
        ],
        "hero_image": "../../arxiv-logo.png"
    }
];

const getDateFromString = (dateString) => {
	// Convert date string format to a Date object
	return new Date(dateString);
};
  
  // Sort articles by date from latest to oldest
const sortedArticles = myResearch.sort((a, b) => {
	return getDateFromString(b.date) - getDateFromString(a.date);
});

export default sortedArticles;
