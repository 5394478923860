const SEO = [
	{
		page: "home",
		description:
			"A seasoned AI and Machine Learning Engineer and Solutions Architect dedicated to buildinghighly scalable AI systems and AI research.",
		keywords: ["Anjan", "Anjanava", "Anjanava Biswas", "Anjan Biswas"],
	},

	{
		page: "about",
		description:
			"A seasoned AI and Machine Learning Engineer and Solutions Architect dedicated to building highly scalable AI systems and AI research.",
			keywords: ["Anjan", "Anjanava", "Anjanava Biswas", "Anjan Biswas"],
	},

	{
		page: "articles",
		description:
			"Chronological collection of my technical articles.",
		keywords: ["Anjan", "Anjanava", "Anjanava Biswas", "Anjan Biswas", "Technical Articles"],
	},
	{
		page: "research",
		description:
			"Chronological collection of my AI research papers.",
		keywords: ["Anjan", "Anjanava", "Anjanava Biswas", "Anjan Biswas", "AI Research"],
	},
	{
		page: "projects",
		description:
			"Open-source AI and Cloud technology projects that I have contributed to or are a primary maintainer of.",
		keywords: ["Anjan", "Anjanava", "Anjanava Biswas", "Anjan Biswas"],
	},

	{
		page: "contact",
		description:
			"Feel free to reach out to me via the contact information available on my web-page.",
		keywords: ["Anjan", "Anjanava", "Anjanava Biswas", "Anjan Biswas"],
	},
];

export default SEO;
