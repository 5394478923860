import React from "react";

import Member from "./member";

import INFO from "../../data/user";

import "./styles/memberships.css";

const Memberships = ({media_type="news"}) => {
	return (
		<div className="all-medias-container">
			{
				INFO.memberships.map((media, index) => (
					<div className="all-medias-media" key={index}>
						<Member
							logo={media.logo}						
							society={media.society}
							title={media.title}
                            member_id={media.member_id}
							link={media.link}
						/>
					</div>
				))
			}
		</div>
	);
};

export default Memberships;
