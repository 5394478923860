

const myArticles = [
    {
        "date": "10 Nov 2023",
        "title": "Build trust and safety for generative AI applications with Amazon Comprehend and LangChain",
        "description": "Discover how Amazon Comprehend and LangChain revolutionize generative AI's trust and safety, focusing on data privacy and ethical AI use. Explore the transformative approach to secure, user-centric AI applications.",
        "keywords": [
            "Generative AI",
            "Trust and Safety",
            "Anjan Biswas",
            "Anjanava Biswas"
        ],
        "url": "https://aws.amazon.com/blogs/machine-learning/build-trust-and-safety-for-generative-ai-applications-with-amazon-comprehend-and-langchain/",
        "hero_image": "https://d2908q01vomqb2.cloudfront.net/f1f836cb4ea6efb2a0b1b99f41ad8b103eff4b59/2023/11/07/ml-15084-image010-new-1153x630.png"
    },
    {
        "date": "24 Oct 2023",
        "title": "Intelligent document processing with Amazon Textract, Amazon Bedrock, and LangChain",
        "description": "Discover how Amazon Textract, Amazon Bedrock, and LangChain revolutionize intelligent document processing by turning unstructured data into actionable insights. This approach significantly enhances document handling efficiency and adaptability, paving the way for dynamic, AI-powered document management solutions.",
        "url": "https://aws.amazon.com/blogs/machine-learning/intelligent-document-processing-with-amazon-textract-amazon-bedrock-and-langchain",
        "keywords": [
            "Generative AI",
            "Amazon Textract",
            "Trust and Safety",
            "Anjan Biswas",
            "Anjanava Biswas"
        ],
        "hero_image": "https://d2908q01vomqb2.cloudfront.net/f1f836cb4ea6efb2a0b1b99f41ad8b103eff4b59/2023/10/13/map-reduce-workflow-1260x604.png"
    },
    {
        "date": "27 Jul 2022",
        "title": "Integrate Amazon SageMaker Data Wrangler with MLOps workflows",
        "description": "Explore the seamless integration of Amazon SageMaker Data Wrangler with MLOps workflows, showcasing a streamlined process for transforming ad-hoc ML models into scalable business solutions. This approach emphasizes automation in the ML lifecycle, highlighting efficiency in data preparation and model accuracy improvement.",
        "url": "https://aws.amazon.com/blogs/machine-learning/integrate-amazon-sagemaker-data-wrangler-with-mlops-workflows/",
        "keywords": [
            "MLOps",
            "Amazon SageMaker",
            "SageMaker DataWrangler",
            "Anjan Biswas",
            "Anjanava Biswas"
        ],
        "hero_image": "https://d2908q01vomqb2.cloudfront.net/f1f836cb4ea6efb2a0b1b99f41ad8b103eff4b59/2022/07/27/data-wrangler-mlops-workflows.jpg"
    },
    {
        "date": "29 Oct 2021",
        "title": "Prevent Fake Account Sign-ups in Real Time with AI using Amazon Fraud Detector",
        "description": "Discover how Amazon Fraud Detector can be leveraged in real-time to prevent fraudulent account sign-ups, enhancing online business security through AI. This solution integrates seamlessly with Amazon Cognito custom authentication workflows, offering a robust defense against online fraud and platform abuse.",
        "url": "https://aws.amazon.com/blogs/machine-learning/prevent-fake-account-sign-ups-in-real-time-with-ai-using-amazon-fraud-detector/",
        "keywords": [
            "Fraud Prevention",
            "Amazon Fraud Detector",
            "SageMaker DataWrangler",
            "Anjan Biswas",
            "Anjanava Biswas"
        ],
        "hero_image": "https://d2908q01vomqb2.cloudfront.net/f1f836cb4ea6efb2a0b1b99f41ad8b103eff4b59/2021/10/28/ML-6059-featured-1260x630.png"
    },
    {
        "date": "15 Aug 2022",
        "title": "Part 1: Intelligent Document Processing with AWS AI Services",
        "description": "Explore how AWS AI services streamline intelligent document processing across various industries by automating information extraction from documents, thus enabling timely decisions and enhancing customer satisfaction through reduced manual efforts and improved accuracy.",
        "url": "https://aws.amazon.com/blogs/machine-learning/part-1-intelligent-document-processing-with-aws-ai-services/",
        "keywords": [
            "Intelligent Document Processing",
            "Amazon Textract",
            "Amazon Comprehend",
            "Anjan Biswas",
            "Anjanava Biswas"
        ],
        "hero_image": "https://d2908q01vomqb2.cloudfront.net/f1f836cb4ea6efb2a0b1b99f41ad8b103eff4b59/2022/08/03/IDP-Blogs-Arch-996x630.png"
    },
    {
        "date": "15 Aug 2022",
        "title": "Part 2: Intelligent Document Processing with AWS AI Services",
        "description": "Delve into advanced intelligent document processing with AWS AI services in Part 2 of this series, covering the final stages of an IDP workflow. This segment emphasizes enriching document data and integrating human review for enhanced accuracy and efficiency in handling documents across industries.",
        "url": "https://aws.amazon.com/blogs/machine-learning/part-2-intelligent-document-processing-with-aws-ai-services/",
        "keywords": [
            "Intelligent Document Processing",
            "Amazon Textract",
            "Amazon Comprehend",
            "Anjan Biswas",
            "Anjanava Biswas"
        ],
        "hero_image": "https://d2908q01vomqb2.cloudfront.net/827bfc458708f0b442009c9c9836f7e4b65557fb/2020/06/03/Blog-Post_thumbnail.png"
    },
    {
        "date": "26 Aug 2022",
        "title": "Process Mortgage Documents with Intelligent Document Processing using Amazon Textract and Amazon Comprehend",
        "description": "Learn how Amazon Textract and Amazon Comprehend automate mortgage document processing, streamlining operations from application to closing. This solution enhances accuracy and efficiency in the lending industry by intelligently extracting and analyzing data from complex documents.",
        "url": "https://aws.amazon.com/blogs/machine-learning/process-mortgage-documents-with-intelligent-document-processing-using-amazon-textract-and-amazon-comprehend/",
        "keywords": [
            "Intelligent Document Processing",
            "Amazon Textract",
            "Amazon Comprehend",
            "Anjan Biswas",
            "Anjanava Biswas"
        ],
        "hero_image": "https://d2908q01vomqb2.cloudfront.net/f1f836cb4ea6efb2a0b1b99f41ad8b103eff4b59/2022/08/10/Arch-Diag-Mortgage.jpg"
    },
    {
        "date": "19 Apr 2023",
        "title": "Amazon Comprehend document classifier adds layout support for higher accuracy",
        "description": "Amazon Comprehend's document classifier now supports layout awareness, significantly enhancing classification accuracy. This update allows for training custom models with documents in their native formats like PDF and Word, leveraging both text and layout for better performance.",
        "url": "https://aws.amazon.com/blogs/machine-learning/amazon-comprehend-document-classifier-adds-layout-support-for-higher-accuracy/",
        "keywords": [
            "Document Classification",
            "Amazon Textract",
            "Amazon Comprehend",
            "Anjan Biswas",
            "Anjanava Biswas"
        ],
        "hero_image": "https://d2908q01vomqb2.cloudfront.net/f1f836cb4ea6efb2a0b1b99f41ad8b103eff4b59/2023/04/19/comprehend-custom-classifier-layout-support.jpg"
    },
    {
        "date": "02 Dec 2022",
        "title": "Introducing one-step classification and entity recognition with Amazon Comprehend for intelligent document processing",
        "description": "Amazon Comprehend introduces a one-step solution for document classification and entity recognition, streamlining intelligent document processing. This enhancement enables direct analysis of semi-structured documents in native formats, simplifying workflows and improving efficiency.",
        "url": "https://aws.amazon.com/blogs/machine-learning/introducing-one-step-classification-and-entity-recognition-with-amazon-comprehend-for-intelligent-document-processing/",
        "keywords": [
            "Document Classification",
            "Amazon Textract",
            "Amazon Comprehend",
            "Anjan Biswas",
            "Anjanava Biswas"
        ],
        "hero_image": "https://d2908q01vomqb2.cloudfront.net/f1f836cb4ea6efb2a0b1b99f41ad8b103eff4b59/2022/12/02/one-step-classification-comprehend.jpg"
    },
    {
        "date": "20 Sep 2023",
        "title": "Balancing Innovation With Safety & Privacy in the Era of Large Language Models (LLM)",
        "description": "The importance of balancing innovation with safety and privacy concerns in the context of developing and using large language models (LLMs). This balance is crucial in the era of rapidly advancing AI technologies, to ensure that while pushing the boundaries of what's possible with AI, we also safeguard user data and uphold ethical standards.",
        "url": "https://towardsdatascience.com/balancing-innovation-with-safety-privacy-in-the-era-of-large-language-models-llm-a63570e4a24a",
        "keywords": [
            "Generative AI",
            "Safety & Privacy",
            "Large Language Models",
            "Anjan Biswas",
            "Anjanava Biswas"
        ],
        "hero_image": "../../tds.png"
    },
    {
        "date": "02 Oct 2023",
        "title": "Intelligent Document Processing with AWS AI Services and Amazon Bedrock",
        "description": "The importance of balancing innovation with safety and privacy concerns in the context of developing and using large language models (LLMs). This balance is crucial in the era of rapidly advancing AI technologies, to ensure that while pushing the boundaries of what's possible with AI, we also safeguard user data and uphold ethical standards.",
        "url": "https://opendatascience.com/intelligent-document-processing-with-aws-ai-services-and-amazon-bedrock/",
        "keywords": [
            "Generative AI",
            "Amazon Bedrock",
            "Large Language Models",
            "Intelligent Document Processing",
            "Anjan Biswas",
            "Anjanava Biswas"
        ],
        "hero_image": "odsc-1.png"
    },
    {
        "date": "25 Oct 2021",
        "title": "Deploying and scaling Apache Solr on Kubernetes",
        "description": "Learn how to deploy and scale Apache Solr on Kubernetes for a highly available, fault-tolerant enterprise-grade search platform. This guide covers everything from utilizing Amazon EKS for managing Kubernetes to monitoring with Prometheus, offering insights into creating a scalable and stable Solr cluster.",
        "url": "https://aws.amazon.com/blogs/opensource/deploying-and-scaling-apache-solr-on-kubernetes/",
        "keywords": [
            "Apache Solr",
            "Amazon EKS",
            "Enterprise Search",
            "Intelligent Document Processing",
            "Anjan Biswas",
            "Anjanava Biswas"
        ],
        "hero_image": "https://d2908q01vomqb2.cloudfront.net/ca3512f4dfa95a03169c5a670a4c91a19b3077b4/2021/10/13/anjanavb_apache-solr-kubernetes_feature.png"
    },
    {
        "date": "26 Sep 2022",
        "title": "Introducing Self-Service Quota Management and Higher Default Service Quotas for Amazon Textract",
        "description": "The article discusses self-service quota management for Amazon Textract via the AWS Service Quotas console, alongside higher default service quotas in select AWS Regions, facilitating better scaling of Amazon Textract usage and quicker processing of quota increase requests.",
        "url": "https://aws.amazon.com/blogs/machine-learning/introducing-self-service-quota-management-and-higher-default-service-quotas-for-amazon-textract/",
        "keywords": [
            "Quota Management",
            "Amazon Textract",
            "Intelligent Document Processing",
            "Anjan Biswas",
            "Anjanava Biswas"
        ],
        "hero_image": "https://d2908q01vomqb2.cloudfront.net/f1f836cb4ea6efb2a0b1b99f41ad8b103eff4b59/2022/09/26/introducing-self-service-quota-management.jpg"
    },
    {
        "date": "07 Jun 2023",
        "title": "Announcing enhanced table extractions with Amazon Textract",
        "description": "Amazon Textract's table extraction feature has been enhanced, simplifying the process of gleaning information from a variety of documents. These improvements enable more effective extraction of titles, footers, and other table elements, furthering the ease of data interpretation.",
        "url": "https://aws.amazon.com/blogs/machine-learning/announcing-enhanced-table-extractions-with-amazon-textract/",
        "keywords": [
            "Amazon Textract",
            "Table Extraction",
            "OCR",
            "Intelligent Document Processing",
            "Anjan Biswas",
            "Anjanava Biswas"
        ],
        "hero_image": "https://d2908q01vomqb2.cloudfront.net/f1f836cb4ea6efb2a0b1b99f41ad8b103eff4b59/2023/06/07/enhanced-table-extractions.jpg"
    },
    {
        "date": "15 May 2023",
        "title": "Introducing Amazon Textract Bulk Document Uploader for Enhanced Evaluation and Analysis",
        "description": "Amazon Textract introduces a Bulk Document Uploader feature, enhancing document processing capabilities. This tool enables efficient, code-free analysis of large document sets directly through the Amazon Textract console, supporting up to 150 documents per request for a comprehensive and user-friendly evaluation.",
        "url": "https://aws.amazon.com/blogs/machine-learning/introducing-amazon-textract-bulk-document-uploader-for-enhanced-evaluation-and-analysis/",
        "keywords": [
            "Amazon Textract",
            "OCR",
            "Intelligent Document Processing",
            "Anjan Biswas",
            "Anjanava Biswas"
        ],
        "hero_image": "https://d2908q01vomqb2.cloudfront.net/f1f836cb4ea6efb2a0b1b99f41ad8b103eff4b59/2023/05/15/introducing-textract-bulk-uploader.jpg"
    },
    {
        "date": "29 Nov 2018",
        "title": "NodeJS Runtime Environment with AWS Lambda Layers",
        "description": "Build NodeJS applications with AWS Lambda Serverless functions using Lambda Layers to manage dependencies.",
        "url": "https://medium.com/@anjanava.biswas/nodejs-runtime-environment-with-aws-lambda-layers-f3914613e20e",
        "keywords": [
            "Javascript",
            "AWS Lambda",
            "Serverless",
            "Anjan Biswas",
            "Anjanava Biswas"
        ],
        "hero_image": "../../lambda-runtime.png"
    },
    {
        "date": "27 Nov 2019",
        "title": "AWS Cognito User Pool Federation With Onelogin IdP",
        "description": "This article discusses how to use AWS Cognito User Pools with SAML Identity Federation. Cognito lets you add user sign-up, sign-in, and access control to your web and mobile apps quickly and easily. But more importantly Cognito lets you manage user’s access to the AWS Services (like S3) with fine grain permissions through temporary credential tokens from underlying STS (Security Token Service).",
        "url": "https://medium.com/@anjanava.biswas/aws-cognito-user-pool-federation-with-onelogin-idp-4b1962127b0b",
        "keywords": [
            "Amazon Cognito",
            "Identity Federation",
            "Authentication",
            "Anjan Biswas",
            "Anjanava Biswas"
        ],
        "hero_image": "../../onelogin-cognito.png"
    },
    {
        "date": "07 Dec 2018",
        "title": "Upload Files To AWS S3 From React App —Using AWS Amplify",
        "description": "This article discusses how to use AWS Amplify to let users upload files to S3 buckets from a React app. The use case is simple — authenticate users of your React app from the browser through Cognito and allow them to upload files to an S3 bucket.",
        "url": "https://medium.com/@anjanava.biswas/uploading-files-to-aws-s3-from-react-app-using-aws-amplify-b286dbad2dd7",
        "keywords": [
            "Amazon Cognito",
            "Identity Federation",
            "Amazon S3",
            "AWS Amplify",
            "Anjan Biswas",
            "Anjanava Biswas"
        ],
        "hero_image": "../../s3-amplify.png"
    },
    {
        "date": "21 Nov 2023",
        "title": "Amazon Textract’s new Layout feature introduces efficiencies in general purpose and generative AI document processing tasks",
        "description": "Amazon Textract introduces a Layout feature enhancing document processing tasks by extracting elements like paragraphs, titles, and lists, aligned with human reading patterns. This update allows for more efficient information extraction from documents, aiding in tasks like financial report cataloging and improving generative AI task accuracy.",
        "url": "https://aws.amazon.com/blogs/machine-learning/amazon-textracts-new-layout-feature-introduces-efficiencies-in-general-purpose-and-generative-ai-document-processing-tasks/",
        "keywords": [
            "Amazon Textract",
            "Layout Detection",
            "Generative AI",
            "Anjan Biswas",
            "Anjanava Biswas"
        ],
        "hero_image": "https://d2908q01vomqb2.cloudfront.net/f1f836cb4ea6efb2a0b1b99f41ad8b103eff4b59/2023/11/17/layout-textractor-975x630.jpg"
    }
];

const getDateFromString = (dateString) => {
	// Convert date string format to a Date object
	return new Date(dateString);
};
  
  // Sort articles by date from latest to oldest
const sortedArticles = myArticles.sort((a, b) => {
	return getDateFromString(b.date) - getDateFromString(a.date);
});

export default sortedArticles;
