import React from "react";
import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faLink } from "@fortawesome/free-solid-svg-icons";

import "./styles/member.css";

const Member = (props) => {
	const { logo, society, title, link } = props;

	return (
		<React.Fragment>
			<div className="member">
				<Link to={link} target="_blank">
					<div className="member-container">
						<div className="member-logo">
							<img src={logo} alt="logo" />
						</div>						
						<div className="member-title">{society}</div>
                        <div className="member-description">{title}</div>
                        {/* <div className="member-description">{member_id}</div> */}
					</div>
				</Link>
			</div>
		</React.Fragment>
	);
};

export default Member;
