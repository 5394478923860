const INFO = {
	main: {
		title: "Anjan Biswas | Portfolio",
		name: "Anjan Biswas",
		email: "anjan.biswas@ieee.org",
		logo: "../logo.png",
	},

	socials: {
		twitter: "https://twitter.com/itsrealAJB",
		github: "https://github.com/anjanvb",
		linkedin: "https://linkedin.com/in/anjanavabiswas",
		// instagram: "https://instagram.com/",
		stackoverflow: "https://stackoverflow.com/users/1137672/anjan-biswas",
		// facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Anjan Biswas",
		description:
			"Anjan, a Sr. AI Specialist Solutions Architect at AWS, leads in AI and ML, focusing on generative AI, computer vision, natural language processing (NLP), and AI safety and alignment. With 16 years in software and cloud tech and holding 7 AWS Certifications, Anjan excels in AI-driven system development and strategic implementation. His leadership marries technical innovation with business goals, passionately pushing the boundaries of AI in cloud computing.",
	},

	about: {
		title: "Applied AI Specialist & Enterprise Transformation Leader",
		description:
			"As a Sr. AI Specialist Solutions Architect at Amazon Web Services (AWS), Anjan leads and supports global initiatives in generative AI, computer vision, natural language processing, and applied AI. With over 16 years of experience in software engineering and cloud technology, he has designed, developed, and implemented enterprise-level, complex, and large-scale systems using AWS products and solutions. <br/><br/>Anjan is a TOGAF® Level 2 Certified Professional and holds 7 AWS Certifications at Professional and Specialty levels. He has a solid background in cloud transformation and domain architecture, and work closely with stakeholders, project managers, developers, and focus groups to align strategy, processes, and IT assets with business goals. He is a technologist, an avid supporter, a researcher, and a contributor to open source projects. He is passionate about building the future of cloud computing with AI.",
	},

	articles: {
		title: "Advancing the Frontiers: A Collection of My Technical Contributions in AI and Machine Learning",
		description:
			"Chronological collection of my AI and ML technical publications, architectural best practices, and more.",
	},

	research: {
		title: "Advancing AI Research: A Collection of Research Papers in AI and Machine Learning",
		description:
			"Chronological collection of my AI and ML research journal publications.",
	},

	medias: [
		{
			title: "How the public sector is tackling its most challenging problems with AI",
			description:
				"How the public sector is tackling its most challenging problems with AI",
			logo: "../../dj-logo.png",
			linkText: "Read the article",
			link: "https://www.digitaljournal.com/tech-science/how-the-public-sector-is-tackling-its-most-challenging-problems-with-ai/article",
		},
		{
			title: "Battling Multi-Billion Dollar Online Fraud Industry with AI and Machine Learning",
			description:
				"Battling Multi-Billion Dollar Online Fraud Industry with AI and Machine Learning",
			logo: "../../entp-logo.png",
			linkText: "Read the article",
			link: "https://www.entrepreneur.com/ka/business-news/battling-multi-billion-dollar-online-fraud-industry-with-ai/465795",
		},
		{
			title: "Generative AI, The Next Big Thing in Intelligent Document Processing: Real-world Applications from an AI Solutions Architect",
			description:
				"Generative AI, The Next Big Thing in Intelligent Document Processing: Real-world Applications from an AI Solutions Architect",
			logo: "../../tech-b-logo.png",
			linkText: "Read the article",
			link: "https://techbullion.com/generative-ai-the-next-big-thing-in-intelligent-document-processing-real-world-applications-from-an-ai-solutions-architect/",
		},
		{
			title: "How to Ensure Trust While Pushing Boundaries in Generative AI? AI Architect Anjanava Biswas Shares Insights",
			description:
				"How to Ensure Trust While Pushing Boundaries in Generative AI? AI Architect Anjanava Biswas Shares Insights",
			logo: "../../tech-t-logo.png",
			linkText: "Read the article",
			link: "https://www.techtimes.com/articles/297735/20231019/how-to-ensure-trust-while-pushing-boundaries-in-generative-ai-ai-architect-anjanava-biswas-shares-insights.htm",
		},
		{
			title: "AI and Open-Source: How AI Architect Anjanava Biswas’ Latest Research is Shaping Open-Source Adoption",
			description:
				"Amazon AI engineers like Anjanava Biswas are helping make open-source AI mainstream through cutting-edge research",
			logo: "../../gbf_logo.png",
			linkText: "Read the article",
			link: "https://www.globalbankingandfinance.com/ai-and-open-source-how-ai-architect-anjanava-biswas-latest-research-is-shaping-open-source-adoption/",
		},
		{
			title: "Anjanava Biswas Wins a 2024 Global Recognition Award™",
			description:
				"Anjanava Biswas has been honored with a 2024 Global Recognition Award™.",
			logo: "../../business_insider_Logo.png",
			linkText: "Read the article",
			link: "https://markets.businessinsider.com/news/stocks/anjanava-biswas-wins-a-2024-global-recognition-award-1033245402",
		},
		{
			title: "AI Expert Anjanava Biswas Honored with the International Achievers Award by the Indian Achievers’ Forum",
			description:
				"AI Expert Anjanava Biswas Honored with the International Achievers Award by the Indian Achievers’ Forum",
			logo: "../../asiaone-logo.png",
			linkText: "Read the article",
			link: "https://www.asiaone.com/business/ai-expert-anjanava-biswas-honored-international-achievers-award-indian-achievers-forum",
		},
		{
			title: "Anjanava Biswas’s Impactful Contributions To AI And Machine Learning Earn International Acclaim",
			description:
				"Anjanava Biswas’s Impactful Contributions To AI And Machine Learning Earn International Acclaim",
			logo: "../../outlook-logo.png",
			linkText: "Read the article",
			link: "https://www.outlookindia.com/hub4business/anjanava-biswass-impactful-contributions-to-ai-and-machine-learning-earn-international-acclaim",
		}
	],

	memberships: [
		{
			society: "Senior Member",
			title: "Institute of Electrical and Electronics Engineers (IEEE, USA)",			
			member_id: "#99706233",
			logo: "../../IEEE-Logo.png",
			link: "https://www.ieee.org/",
		},
		{
			society: "Fellow Member",
			title: "The Institution of Engineering and Technology (IET, UK)",
			member_id: "#1101162413",
			logo: "../../IET-Logo.png",
			link: "https://www.theitet.org/",
		},
		{
			society: "Fellow Member",
			title: "British Computer Society, The Chartered Institute for IT (BCS, UK)",		
			member_id: "#995136536",
			logo: "../../BCS-logo.png",
			link: "https://www.bcs.org/",
		},
		{
			society: "Fellow Member",
			title: "The Institution of Electronics and Telecommunication Engineers (IETE, India)",			
			member_id: "#F-504185",
			logo: "../../IETE-Logo.png",
			link: "https://www.iete.org/",
		},
		{
			society: "Active Contributor - OWASP Top 10 for Large Language Model Applications",
			title: "The Open Worldwide Application Security Project (OWASP)",			
			member_id: "#F-504185",
			logo: "../../OWASP-logo.png",
			link: "https://owasp.org/www-project-top-10-for-large-language-model-applications/",
		},
		{
			society: "Artificial Intelligence (AI) Advisory Board Member",
			title: "Security Industry Association (SIA)",			
			member_id: "#F-504185",
			logo: "../../SIA-logo.png",
			link: "https://www.securityindustry.org/committee/ai-advisory-board/",
		},
		{
			society: "Artificial Intelligence (AI) Advisory Memeber",
			title: "IEEE Industry Engagement Committee (IEC)",			
			member_id: "#F-504185",
			logo: "../../IEEE-IEC.png",
			link: "https://www.ieee.org/about/corporate/industry-engagement-committee.html",
		}
	],

	awards: [
		{
			title: "Anjanava Biswas receives Gold 2024 Stevie Awards for Technology Excellence",
			description:
				"Anjanava Biswas receives Gold 2024 Stevie Awards for Technology Excellence",
			logo: "../../Stevie-logo.png",
			linkText: "See announcement",
			link: "https://stevieawards.com/Tech",
		},
		{
			title: "Anjanava Biswas receives 2024 Globee Silver Awards",
			description:
				"Anjanava Biswas receives 2024 Globee Technology Awards and 2024 Globee Golden Bridge Awards",
			logo: "../../Globee-Logo.png",
			linkText: "See announcement",
			link: "https://globeeawards.com/technology/winners/",
		},
		{
			title: "Anjanava Biswas receives 2024 TITAN Platinum Award",
			description:
				"Anjanava Biswas receives 2024 TITAN Platinum Award for Innovations in safe generative AI and multi-modal AI applications",
			logo: "../../TITAN-Logo.png",
			linkText: "See announcement",
			link: "https://thetitanawards.com/winner-info.php?id=4018",
		},
		{
			title: "Anjanava Biswas receives 2024 Global Recognition Award",
			description:
				"Anjanava Biswas receives 2024 Global Recognition Award",
			logo: "../../GRA-logo2.png",
			linkText: "See announcement",
			link: "https://globalrecognitionawards.org/winners/2024/anjanava-biswas-wins-2024-global-recognition-award/",
		},
		{
			title: "Anjanava Biswas Honored with International Achievers' Award, 2024",
			description:
				"Anjanava Biswas Honored with International Achievers' Award, 2024",
			logo: "../../iaf-logo.png",
			linkText: "See announcement",
			link: "https://www.iafindia.com/mr-anjanava-biswas/",
		}
	],

	projects: [
		{
			title: "Rhubarb",
			description:
				"A Python framework built from the ground-up to perform document understanding with multi-modal Large Language Models",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://awslabs.github.io/rhubarb/index.html#",
		},

		{
			title: "Intelligent Document Processing with AWS AI Services",
			description:
				"Intelligent Document Processing with AWS AI Services and Generative AI",
			logo: "../../github.png",
			linkText: "View Project",
			link: "https://github.com/aws-samples/aws-ai-intelligent-document-processing",
		},

		{
			title: "Amazon Textract Layout Linearizer",
			description:
				"Get layout information from complex document formats using Amazon Textact AI OCR",
			logo: "../../github.png",
			linkText: "View Project",
			link: "https://github.com/aws-samples/amazon-textract-textractor/tree/master/prettyprinter#get-linearized-text-from-layout-using-get_text_from_layout_json-method",
		},

		{
			title: "Amazon Textract Textractor",
			description:
				"Textractor is a python package created to seamlessly work with 4 popular Amazon Textract APIs.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://aws-samples.github.io/amazon-textract-textractor/index.html",
		},

		{
			title: "IDP Cloud Development Kit (CDK) Constructs",
			description:
				"L3 Cloud Development Kit Constructs to deploy Intelligent Document Processing workflows at scale",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com/aws-samples/amazon-textract-idp-cdk-constructs",
		},

		{
			title: "IDP Cloud Development Kit (CDK) Constructs - Samples",
			description:
				"Workflow samples using IDP CDK Constructs",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com/aws-solutions-library-samples/guidance-for-low-code-intelligent-document-processing-on-aws",
		},

		{
			title: "Trust and Safety with Amazon Comprehend with LangChain",
			description:
				"Implement trust, safety and moderation using Amazon Comprehend with LangChain for your Gen AI Applications",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://python.langchain.com/v0.1/docs/guides/productionization/safety/amazon_comprehend_chain/",
		},

		{
			title: "LLM Safety and Privacy",
			description:
				"Implement trust, safety and privacy LangChain for your Gen AI Applications",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com/annjawn/llm-safety-privacy",
		},

		{
			title: "Personal Health Information (PHI) De-identification",
			description:
				"De-identify medical documents with Amazon Comprehend Medical and Amazon Textract with a web based UI experience.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com/aws-samples/aws-ai-phi-deidentification",
		},

		{
			title: "Protect your Web-app's sign-up workflow to minimize spammy sign-ups with AI",
			description:
				"A CDK Application containing Stacks that deploy necessary resources required to implement a real-time fraud detection and prevention system using Amazon Fraud Detector and Amazon Cognito.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com/aws-samples/amazon-fraud-detector-with-cognito",
		},

		{
			title: "Setup Amazon Fraud Detector End-to-End",
			description:
				"Sample datasets and code for operationalizing Amazon Fraud Detector using SageMaker DataWrangler, Feature Store, and Pipelines.",
			logo: "../../github.png",
			linkText: "View Project",
			link: "https://github.com/aws-samples/amazon-fraud-detector-end-to-end",
		},

		{
			title: "Human-in-the-loop for Intelligent Document Processing",
			description:
				"AWS Intelligent Document Processing Human-in-the-loop solution let's you human review Amazon Textract inferences",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com/aws-samples/aws-ai-idp-human-in-loop",
		},

		{
			title: "Implement Data Wrangler Workflows with MWAA and Step Functions",
			description:
				"Integrate SageMaker Data Wrangler into your MLOps workflows with Amazon SageMaker Pipelines, AWS Step Functions, and Amazon Managed Workflow for Apache Airflow (MWAA)",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com/aws-samples/sm-data-wrangler-mlops-workflows",
		},

		{
			title: "Deploy and scale Apache Solr on Amazon EKS",
			description:
				"Project with configuration files required to deploy an Amazon Elastic Kubernetes Service cluster with Apache Solr and Apache Zookeeper.",
			logo: "../../github.png",
			linkText: "View Project",
			link: "https://github.com/aws-samples/amazon-eks-arch-apache-solr",
		},
	],
};

export default INFO;
